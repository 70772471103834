import { yupResolver } from '@hookform/resolvers/yup';
import { HeadFC, navigate } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

import { setSessionStorage } from '../../../utilities/storage';
import { Button } from '../../components/Button';
import { ErrorAlert } from '../../components/ErrorAlert';
import Footer from '../../components/Footer';
import { TextField } from '../../components/Input';
import { Loading } from '../../components/Loading';
import NavBar from '../../components/Nav';
import { Spacer } from '../../components/Spacer';
import { View } from '../../components/View';
import { fontWeightType } from '../../constants/enums';
import Base from '../../layout/Base';
import server from '../../services/fetchApi';
import { sentryCaptureException } from '../../services/sentry';
import { HomeHeaderText, HomeHeaderTitle } from '../../styled-components/pages/home.style';

const Title = styled(HomeHeaderTitle)`
  text-align: left;
  width: 100%;
`;

const Text = styled(HomeHeaderText)`
  text-align: left;
  max-width: 100%;
`;

const Body = styled(View)`
  height: 70vh;
  justify-content: center;
`;

const FormStyled = styled.form`
  --horizontal-padding: 0.3125rem;

  box-sizing: border-box;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  width: 100%;

  input {
    box-sizing: border-box;
    height: fit-content;
    padding: 0.625rem;
  }
`;

const ErrorContainer = styled.div`
  margin-top: 1rem;
  div {
    margin: 0;
  }
`;

const schema = yup.object().shape({
  email: yup
    .string()
    .email('El formato de email es inválido')
    .required('El nombre de usuario o correo electrónico es requerido.'),
  password: yup
    .string()
    .required('La contraseña es requerida')
    .min(8, 'La contraseña es demasiado corta  - debe contener mínimo 8 caracteres.'),
});

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setSessionStorage('token', '');
  }, []);

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      const body = {
        user: { ...data },
        device: {
          device_name: 'web',
          device_id: '00000000-0000-0000-0000-000000000000',
        },
      };
      const response: any = await server.post('users/sign_in', { ...body });
      const token = response.headers.authorization;
      if (token) {
        server.setAuthToken(token);
        if (typeof window !== 'undefined') {
          setSessionStorage('token', token);
        }
      }

      const activeSubscription = response?.data?.active_subscription || {};
      const goToPlans =
        activeSubscription?.expired ||
        activeSubscription?.is_freemium ||
        activeSubscription?.status_subscription === 'inactive';

      if (goToPlans) {
        navigate('/plans');
      } else navigate('/account');
    } catch (error) {
      setError(true);
      setIsLoading(false);
      sentryCaptureException(error);
    }
  };

  const { email, password } = errors || {};

  if (isLoading) {
    return (
      <Loading
        onPress={() => null}
        mainTitle="Por favor espere un momento..."
        type="loading"
        buttonLabel=""
      />
    );
  }

  return (
    <Base>
      <NavBar spacerSize="0" logoCenter />
      <Body>
        {error ? (
          <ErrorContainer>
            <ErrorAlert message="Ha ocurrio un error al intentar iniciar. Correo o contraseña incorrectos" />
          </ErrorContainer>
        ) : null}
        <Title color="grayBlack" fontWeight={fontWeightType.SemiBold} className="title">
          Inicia sesión en tu cuenta
        </Title>
        <Spacer size="30" />
        <Text color="mainGray" fontWeight={fontWeightType.Medium} className="text">
          Introduce tu correo electronico registrado seguido de la contraseña
        </Text>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <Spacer size="30" />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField {...field} type="text" label="Correo electrónico" messageError={email} />
            )}
          />
          <Spacer size="30" />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField {...field} type="password" label="Contraseña" messageError={password} />
            )}
          />
          <Spacer size="30" />
          <Button type="submit" customWidth="300px" customHeight="44px" onClick={() => null}>
            Inicia sesión
          </Button>
        </FormStyled>
        <Spacer size="30" />
      </Body>
      <Footer />
    </Base>
  );
};

export default LoginPage;

export const Head: HeadFC = () => <title>Login Page</title>;
