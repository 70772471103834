import styled from 'styled-components';

import { Body2 } from '../../components/Fonts/Body';
import { H4 } from '../../components/Fonts/HTags';
import { View } from '../../components/View/index';

export const HomeHeader = styled(View.withComponent('header'))`
  align-items: center;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: ${({ theme }) => theme.breakpoints.xxl};
  }
`;

export const HomeHeaderImage = styled.img`
  margin-bottom: 23px;
  margin-top: 50px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 11px;
    margin-top: 11px;
  }
`;

export const HomeHeaderTitle = styled(H4)`
  margin-bottom: 23px;
  margin-top: 50px;
  max-width: 300px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 0px;
    margin-top: 57px;
    max-width: inherit;
    order: -1;
    font-size: 30px;
  }
`;

export const HomeHeaderText = styled(Body2)`
  margin-bottom: 30px;
  max-width: 300px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 460px;
    font-size: 18px;
  }
`;

export const HomeHeaderAppsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 50%;
  }
`;

export const AppsContainerImg = styled.img`
  margin-right: 6px;
  margin-left: 6px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 228px;
    height: 67px;
  }
`;
